import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
import banner from '../../../../assets/images/services-banner.jpg';
/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        caption={
          <h2 className="x-large-heading color-white">
            Retirement Plan Services.
          </h2>
        }
        bannerImage={banner}
      />
      <ContentInner
        heading={'Retirement Plan Services.'}
        content={
          <p>
            For many individuals, their 401k plan may be the largest source of
            retirement funds and thus it becomes particularly incumbent upon
            them to make good decisions in managing it. DGM can help individuals
            with the management of their retirement plan in the context of their
            overall life path and goals.
            <br /> <br />
            As an employer, your 401k plan and benefits can be a great
            recruiting tool as well as a way to retain valued employees. Through
            our trusted partners, we can provide options to help you design a
            plan that fits your work force and financial situation. We can help
            you put your plan participants on the path to best-case outcomes
            with low-cost investment options and access to third-party managed
            account and advice services. All of this comes in the wrapper of
            support for people's full financial lives and life paths. We provide
            the resources, solutions, knowledge and support for anyone to best
            manage their retirement plan.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
